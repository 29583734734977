import { template as template_306579b5d49948c6b8b9f094b1f42b56 } from "@ember/template-compiler";
const FKLabel = template_306579b5d49948c6b8b9f094b1f42b56(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
